const Gallery = { methods: {}, computed: {}, props: {} };

export default Gallery;

Gallery.metaInfo = function() {
  return {
    title: `${this.$locale["gallery_label"]} - ${this.$locale["app_name"]}`,
  };
};

Gallery.props.modal = {
  type: Object,
  default: () => {},
};

Gallery.props.item = {
  type: Object,
  default: () => {},
};

Gallery.computed.deleteConfirm = function() {
  return {
    cancel: {
      label: this.$locale["cancel"],
      action: () => {
        this.modal.close();
      },
    },
    accept: {
      label: this.$locale["delete"],
      action: () => {
        this.modal.close(() => {
          this.deleteNow();
        });
      },
    },
  };
};

Gallery.methods.itemMenu = function(item) {
  this.view({
    title: this.$locale["file"],
    component: () => import("./GalleryImageMenu.vue"),
    class: "menu bottom",
    closeOutside: true,
    animation: "bottomIn",
    item: item,
  });
};

Gallery.methods.deleteItem = async function(item, cb) {
  this.view({
    title: this.$locale["delete"],
    component: () => import("./GalleryDelete.vue"),
    class: "menu bottom",
    closeOutside: true,
    animation: "bottomIn",
    item: item,
    onDeleted: (data) => {
      if (Go.is(cb, "Function")) {
        cb(data);
      }
    },
  });
};

Gallery.methods.deleteNow = async function() {
  this.loading = await this.loader({ message: this.$locale["deleting"] + "...", showProgress: false });
  this.config = { headers: { "use-form": false } };

  await Go.sleep(APP_TRANSITION_TIME);

  try {
    const formData = new FormData();
    formData.append("id", this.item._id);
    this.req = await this.$http.post(`${this.$apiHost}gallery/deleteFile`, formData);
    this.responseDelete(this.req.data);
  } catch (error) {
    this.loading.close(() => {
      this.alert(Go.getErrorMessage(error));
    });
  }
};

Gallery.methods.responseDelete = function(data = {}) {
  this.loading.close(() => {
    if (!data.success) {
      return this.alert(data);
    }

    if (data.success._id) {
      Go.addClass(`[file="file${data.success._id}"]`, "deleted");
    }

    if (Go.is(this.onDeleted, "Function")) {
      this.onDeleted(data);
    } else {
      data.message && this.alert(data);
    }
  });
};
