<template>
  <div class="Gallery">
    <div class="GalleryContent" :class="{ xpadding: $isMobile || $isTablet }">
      <Spacer num="1" />
      <Title :label="$locale['gallery_label']" :icon="$isAdmin ? 'bars' : 'filter-list'" @icon="galleryMenu">
        <small class="SubTitle">{{ $state["gallery_category"] ? $state["gallery_category"]["name"] : $locale["all_galleries"] }}</small>
      </Title>
      <Spacer num="1" />
      <GalleryCategories />
      <div class="GalleryList">
        <DynamicScroller class="scroller" :items="items" v-bind="scroller">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.size]" :data-index="index">
              <GalleryGroup :item="item" :key="item.id" @click="galleryVisor" />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <div v-if="!$loading && !statistics.total && listed">
          <CardMessage :message="$locale['nothing_found']" />
          <Spacer num="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import gallery from "./gallery.js";
export default {
  mixins: [FeedList, gallery],
  components: {
    GalleryCategories: () => import("./GalleryCategories.vue"),
    GalleryGroup: () => import("./GalleryGroup.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/gallery`,
      storeId: "gallery",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 18,
      cache: true,
      query: `groupof=3`,
      scroller: {
        buffer: 1080,
        "min-item-size": 309,
        "key-field": "id",
        "page-mode": true,
      },
    };
  },
  methods: {
    galleryMenu: function() {
      this.view({
        title: this.$locale["gallery_label"],
        component: () => import("./GalleryMenu.vue"),
        class: "menu bottom",
        closeOutside: true,
        animation: "bottomIn",
      });
    },
    routeUpdate: function() {
      if (!this.$route.query.category) {
        this.state({ gallery_category: undefined });
      }
    },
    galleryVisor: function(item) {
      this.view({
        title: this.$state["gallery_category"] ? this.$state["gallery_category"]["name"] : this.$locale["gallery_label"],
        component: () => import("./GalleryVisor.vue"),
        closeOutside: true,
        class: "GalleryVisor",
        item: item,
      });
    },
  },
  mounted: function() {
    this.routeUpdate();
  },
  watch: {
    "$route.query": function() {
      this.routeUpdate();
      this.updateFeed();
    },
    "$store.state.feedKey": function() {
      this.routeUpdate();
      this.updateFeed();
    },
  },
};
</script>
